<template>
    <div class="intro-container">
        <div class="intro-animation">
            <img class="ball-1" src="../../assets/images/Kugel_Geheimnisse.svg">
            <img class="ball-2" src="../../assets/images/Kugel_Geschenke.svg">
            <img class="ball-3" src="../../assets/images/Kugel_Geschichten.svg">
        </div>
    </div>
</template>

<style lang="scss" scoped>
.intro-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: -10;
    .intro-animation {
        position: relative;
        width: 800px;
        height: 600px;
        margin: 4rem auto;
        img {
            display: block;
            width: 14rem;
            position: absolute;
            opacity: 0;
        }
        .ball-1 {
            z-index: -9;
            /* top: -50px;
            left: -50px; */
            animation: one 2.6s cubic-bezier(0.76,0.23,0.49,0.85) forwards;
        }
        .ball-2 {
            z-index: -8;
            animation: two 2.2s cubic-bezier(0.76,0.23,0.49,0.85) forwards;
        }
        .ball-3 {
            z-index: -7;
            animation: three 2.8s cubic-bezier(0.76,0.23,0.49,0.85) forwards;
        }
    }
}

@keyframes one {
    0% {
        /* transform: translate(0,0); */
        top: 0;
        left: 0;
    }
    8% {
        opacity: 1;
    }
    35% {
        transform: scale(.98);
    }
    68% {
        transform: scale(1.12);
    }
    100% {
        /* transform: translate(190px, 120px); */
        top: 20%;
        left: 26%;
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes two {
    0% {
        top: 0;
        right: 0;
    }
    9% {
        opacity: 1;
    }
    16% {
        transform: scale(.96);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        top: 25%;
        right: 28%;
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes three {
    0% {
        bottom: 0;
        left: 0;
    }
    7% {
        opacity: 1;
    }
    26% {
        transform: scale(.97);
    }
    56% {
        transform: scale(1.11);
    }
    100% {
        top:50%;
        left: 24%;
        transform: scale(1);
        opacity: 1;
    }
}
</style>